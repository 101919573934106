//2、引入并声明使用插件
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import routes from './routes'
const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function(location, onResolved, onRejected) {

    if (onResolved === undefined && onRejected === undefined) {
        return originPush.call(this, location).catch(() => {})
    } else {
        return originPush.call(this, location, onResolved, onRejected)
    }
}
VueRouter.prototype.replace = function(location, onResolved, onRejected) {

    if (onResolved === undefined && onRejected === undefined) {
        //证明调用的时候只传递了个匹配路由对象，没有传递成功或者失败的回调
        return originReplace.call(this, location).catch(() => {})
    } else {
        //证明调用的时候传递了成功或者失败的回调，或者都有
        return originReplace.call(this, location, onResolved, onRejected)
    }
}

//3、需要向外暴露一个路由器的对象
const router = new VueRouter({
    routes,
    mode: "history"
})

export default router