import request from './axios'
export const reqCategoryList = (query,userId) => {
  return request({
    url: `/project/list?openid=${query}&userId=${userId}`,
    method: 'get',
    // params:query
  })
}

export const reqMap = (query) => {
  return request({
    url: '/service/list',
    method: 'get',
    params: query
  })
}

export const reqjwd = (query) => {
  return request({
    url: '/get/location',
    method: 'get',
    params: query
  })
}