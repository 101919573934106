<template>
  <div id="app">
    <router-view></router-view>
    <loading></loading> 
  </div>
</template>

<script>
import loading from './components/loding.vue'
  export default {
    name: 'App',
    components:{loading},
    methods: {

    },
    created() {
      document.cookie = "OY=1231231232131;path=/;domain=cadayun.com"
      console.log(document.cookie);
      // document.body.removeChild(document.getElementById('spinner'))
      
    },
    // beforeCreate() {
    //   let loading  = document.getElementById('spinner')
    //   if(loading!=null){
    //     document.body.removeChild(loading)
    //   }
    // },

  }
</script>

<style>

</style>