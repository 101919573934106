// 路由按需加载
const Home = () =>
    import ("@/pages/Home/index")
const Map = () =>
    import ('@/pages/Map/index')
    // const Homes = () =>
    //     import ('@/pages/Homes/index')
const select = () =>
    import ('@/pages/Select/index')
const End_page = () =>
    import ('@/pages/End_page/index')
const Error_page = () =>
    import ('@/pages/Error_page/index')
export default [{
        path: '/Home',
        component: Home
    },
    {
        path: '/Map',
        component: Map
    },
    {
        path: '/',
        name: "/home",
        component: () =>
            import ("@/pages/Homes/index"),
    },

    {
        path: '/select',
        component: select
    },
    {
        path: '/end_page',
        component: End_page
    },
    {
        path: '/error_page',
        name: 'Error_page',
        component: Error_page
    },



]