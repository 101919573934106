import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import 'lib-flexible'
import * as API from '@/api'
Vue.prototype.$API = API
import VueCookie from 'vue-cookie'
Vue.use(VueCookie)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)
Vue.config.productionTip = false
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);
Vue.prototype.bus = new Vue;
new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')