import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const serve = axios.create({
  baseURL: '/cloud',
  timeout: 20000,
});

serve.interceptors.request.use((config) => {
  NProgress.start();
  return config;
}, );

serve.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response.data;

  }, (error) => {
    NProgress.done();
    alert('发送请求失败' + error.message || '未知错误')
    return new Promise(() => { })
  });

export default serve